import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HoverStyleDirective } from '../../shared/directives/hover-style.directive';

@Component({
  selector: 'app-invalid-category',
  templateUrl: './invalid-category.component.html',
  styleUrls: ['./invalid-category.component.scss'],
  standalone: true,
  imports: [HoverStyleDirective, RouterLink, TranslateModule],
})
export class InvalidCategoryComponent {}
